.work-detail-all {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 40vh;
  gap: 80px;
  margin-bottom: 30%;
}





.navbar {
  position: fixed;
  z-index: 99999;
  width: 100%;
  transition: all 0.5s ease;
}

.logo-cs {
  background-color: aqua;
  height: 80px;
  width: 80px;
}
.links {
  align-items: center;
}
.links-scroll {
  width: auto;
  display: flex;
  gap: 30px;
  transition: all 0.5s ease;
}
.links-scroll a {
  color: white;
  font-family: "HostLight", sans-serif;
  font-size: calc(0.6vw + 0.6vh + 0.5vmin);
  text-decoration: none;
  transition: all 0.5s ease;
}

.links a {
  color: white;
  font-family: "HostLight", sans-serif;
  font-size: calc(0.6vw + 0.6vh + 0.5vmin);
  text-decoration: none;
  transition: all 0.5s ease;
}
.links button {
  color: white;
  background-color: transparent;
  font-family: "HostLight", sans-serif;
  font-size: calc(0.6vw + 0.6vh + 0.5vmin);
  text-decoration: none;
  border: none;
  width: auto;
  transition: all 0.5s ease;
}

.links-scroll button {
  color: white;
  background-color: transparent;
  font-family: "HostLight", sans-serif;
  font-size: calc(0.6vw + 0.6vh + 0.5vmin);
  text-decoration: none;
  border: none;
  width: auto;
  transition: all 0.5s ease;
}
.cs-content {
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease;
}

.accordion-button {
  cursor: pointer;
}

.accordion-content {
  position: relative;
  z-index: -999999;
  top: 4em;
  max-height: 0px;
  display: none;
  padding: 30px 60px 30px 60px;
  color: #f4f4f4;
  background-color: #04010C;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.5s ease;
}

.open {
  max-height: 600px;
  display: flex;
  gap: 5em;
}

.content h1 {
  font-family: "HostRegular", sans-serif;
  font-weight: 500;
  font-size: calc(0.8vw + 0.6vh + 0.5vmin);
}
.content p {
  font-family: "HostLight", sans-serif;
  font-weight: 50;
  font-size: calc(0.4vw + 0.5vh + 0.5vmin);
  color: #f4f4f450;
}

.cs-links {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-end;
}
.cs-top {
  position: relative;
  top: 70px;
  display: flex;
  justify-content: space-between;
  background-color: #04010c;
  padding: 30px 60px 30px 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.cs-top-scroll {
  position: relative;
  top: 70px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: #04010c;
  padding: 6px 60px 6px 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}

.cs-top-scroll h1 {
  font-family: "HostLight", serif;
  font-size: calc(1vw + 1vh + 1.5vmin);
  margin-block-start: 0.2em;
  color: white;
  margin-block-end: 0.2em;
  text-transform: lowercase;
  transition: all 0.5s ease;
}

.cs-top p {
  font-family: "HostLight", sans-serif;
  letter-spacing: 1px;
  font-size: calc(0.5vw + 0.5vh + 0.5vmin);
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: rgba(255, 255, 255, 0.5);
}
.cs-top h1 {
  font-family: "HostRegular", serif;
  font-size: calc(2.5vw + 2.5vh + 2.5vmin);
  margin-block-start: 0em;
  color: white;
  margin-block-end: 0em;
  text-transform: lowercase;
}
.cs-top h2 {
  font-family: "EditorialNew-Light", serif;
  font-size: calc(0.8vw + 0.8vh + 0.8vmin);
  font-weight: 100;
  margin-block-start: 0.5em;
  color: white;
  margin-block-end: 0em;
}

.title-sec {
  width: 90vw;
  height: auto;
  left: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.title-tagline h1 {
  font-family: "Roobert-Medium";
  font-size: 9rem;
  color: white;
  margin-block-end: 0.1em;
  margin-block-start: 0.1em;
}
.title-tagline p {
  font-family: "Roobert-Regular";
  font-size: 1.6rem;
  width: 30%;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  color: white;
  line-height: 40px;
}
.title-tagline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
  width: 80%;
}
.caseStudy-description {
  font-family: "Roobert-Regular";
  color: white;
  font-size: 1.5rem;
  text-align: center;
  width: 70vw;
  margin-bottom: 50px;
}

.sub-details {
  display: flex;
  gap: 60px;
  flex-direction: row;
  font-family: "Roobert-Regular";
}

.sub-details h3 {
  color: grey;
}
.sub-details h1 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
}
.work-detail {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.work-detail-image {
  height: auto;

  width: 85vw;
  margin-bottom: 40px;
}
.work-detail p {
  font-family: "Roobert-Regular";
  color: white;
  font-size: 1.5rem;
  text-align: center;
  width: 70vw;
  margin-bottom: 50px;
}

.header-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.read-or-hide {
  color: rgb(101, 89, 209);
  cursor: pointer;
}

.case-study-images {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-device-width: 1480px) {
  .work-detail-all {
    margin-bottom: 60vh;
  }
  .title-tagline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
    gap: 0;
  }
  .title-tagline p {
    margin-left: 20px;
  }
  .work-detail-image {
    height: 60%;

    width: 95vw;
    margin-bottom: 10px;
  }
}

@media screen and (max-device-width: 812px) {
  .cs-top {
    flex-direction: column;
    padding: 30px 20px 30px 20px;
    gap: 20px;
  }
  .cs-top h1{
    font-size: calc(3vw + 3vh + 3vmin);
  }
  .cs-top p{
    font-size: calc(0.6rem + 0.6vw);
  }

  .links a, .links button{
    font-size: calc(0.7rem + 0.7vw);
  }
  .accordion-content {
    position: relative;
    z-index: -999999;
    top: 4em;
    padding: 30px 20px 30px 20px;
    flex-direction: column;
    gap: 10px;
  }
  .cs-top-scroll h1{
    font-size: calc(2vw + 2vh + 2vmin);
  }

  .cs-top-scroll a, .cs-top-scroll button{
    font-size: calc(0.7rem + 0.7vw);
  }

  .cs-top-scroll {

    align-items: flex-start;
    display: flex;
    gap: 20px;
    padding: 6px 20px 6px 20px;
    flex-direction: column;
  }

  .content h1 {
    font-size: calc(1vw + 1.5vh + 1.5vmin);
  }

  .content p {
    font-size: calc(0.8vh + 0.6vh + 0.6vmin);

  }
  .cs-top h2{
    font-size: calc(2vw + 1vh + 1vmin);
  }

  .cs-links{
    align-items: flex-start;
  }
  .logo-cs{
    display: none;
  }

  .work-detail-image {
    height: 60%;

    width: 95vw;
    margin-bottom: 10px;
  }
  .work-detail-all {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 35vh;
    gap: 80px;
    margin-bottom: 50vh;
  }
  .title-sec {
    width: 100vw;
    height: auto;
    left: 20px;
    position: relative;
  }
  .title-tagline {
    gap: 0px;
    width: 90%;
  }
  .title-tagline h1 {
    line-height: 120px;
  }
  .title-tagline p {
    margin-left: 0px;
    width: 100%;
    margin-block-start: 0.5em;
  }
  .title-tagline h1 {
    font-size: 6rem;
    line-height: normal;
  }
  .work-detail p {
    font-size: 1.2rem;
    width: 90vw;
  }
}
