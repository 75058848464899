.work-main {
  margin-top: calc(4vw + 3vh + 4vmin);

  margin-bottom: 10%;

  margin-left: auto;
  margin-right: auto;
}

.parent-work {
  margin-left: 70px;
  display: flex;
  margin-right: 70px;
  height: 80vh;
  flex-direction: row;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.152);
  margin-bottom: 40px;
}
.cta-cs {
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  font-size: calc(0.5vw + 1vh + 0.8vmin);
  color: white;
  font-family: "Roobert-Regular";
  padding: 20px 20px 20px 32px;
  border-top: 1px solid rgba(255, 255, 255, 0.152);
}

.cs-title {
  padding: 32px 32px 32px 32px;
}
.cs-title h1 {
  margin-block-end: 0em;
  color: white;
  font-size: calc(2vw + 1vh + 1vmin);
  font-family: "HostMedium", sans-serif;
  display: -webkit-box;
  font-weight: 400;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  margin-block-start: 0em;
}
.work-tags {
  display: flex;

  gap: 30px;
  flex-wrap: wrap;
}
.head-work {
  display: flex;
  padding-left: 70px;
  padding-right: 70px;
  margin-bottom: 70px;
  align-items: flex-end;
  justify-content: space-between;
}

.head-work img {
  width: auto;
  height: calc(4vw + 3vh + 4vmin);
}

.head-work p {
  color: #ffffff75;
  margin-block-end: 0px;
  margin-block-start: 0px;
  font-size: 20px;
  width: 60%;
}

.view-all {
  display: flex;
  flex-direction: rows;
  justify-content: space-between;
  height: fit-content;
  padding-left: 70px;
  padding-right: 70px;
  align-items: center;
}

.view-all h1 {
  font-family: "HostMedium", sans-serif;
  font-weight: 100;
  font-size: calc(1rem + 1.6vw);
  color: #ffffff;
}

.work-main h2 {
  font-family: "HostMedium", sans-serif;
  font-size: calc(2rem + 2vw);
  margin-block-start: 0em;
  margin-block-end: 0.2em;
  font-weight: 100;
  width: 100%;
  overflow-x: hidden;
  color: #00fff7;
  text-align: left;

  mix-blend-mode: difference; /* Blend text with background */
}
.tag {
  background-color: rgba(255, 255, 255, 0.129);
  font-family: "HostLight";
  padding: 6px 12px;
  border: 1px solid rgba(255, 255, 255, 0.17);
  color: white;
  margin-right: 10px;
  border-radius: 40px;
}
.cs-title p {
  margin-block-start: 0.5em;
  margin-block-end: 1em;
  color: #7e7e7e;

  font-size: calc(0.5vw + 0.5vh + 0.7vmin);
  font-family: "HostLight";
  font-weight: 100;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
}

.work-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
}
.work-thumbnail {
  width: 70%;
}

.work-main p {
  font-family: "HostLight";
  font-weight: 100;

}

.project-latest {
  display: flex;
  justify-content: space-between;
  height: 80vh;
  width: 100%;
  flex-direction: row;
  margin-bottom: 10px;
}

.project-latest a {
  width: auto;
}

.project-latest-image {
  width: 70%;
  height: 100%;
  position: relative;
}

.thumbnail {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}
.project-latest-description {
  margin-block-start: 0.5em;
  margin-block-end: 0em;
  color: #7e7e7e;
  font-size: calc(0.5vw + 0.5vh + 0.8vmin);
  font-family: "HostLight";
  font-weight: 100;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
}

.project-desc {
  display: flex;
  flex-direction: column;
}

.project-btn3 {
  font-family: "HostMedium", Sans-Serif;
  padding: 10px 24px !important;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background-color: white;
  box-shadow: inset 1px 1px #cfcfcf, inset -1px -1px #ccc, 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: Black;
  text-decoration: none;
}

.project-latest-title {
  margin-block-end: 0em;
  color: white;
  font-size: calc(2vw + 1vh + 1vmin);
  font-family: "HostRegular", sans-serif;
  display: -webkit-box;
  font-weight: 400;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  margin-block-start: 0em;
}

.project-description-div {
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.project-description-div a {
  text-decoration: none;
  font-size: calc(1vw + 1vh + 1vmin);
  color: white;
  font-family: "HostRegular";
}

.project-description-div a:hover {
  cursor: pointer;
}

.case-study-btn {
  text-decoration: none;
  font-size: calc(0.5vw + 1vh + 0.8vmin);
  color: white;

  font-family: "Roobert-Regular";
}
.btn-1:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -10px;
  left: 0px;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
  background: linear-gradient(
    to right,
    #5f14d8 0%,
    #1cd359 30%,
    #198fb3 70%,
    #f4752b 100%
  );
}
.btn-1:hover:after {
  background: linear-gradient(
    to right,
    #5f14d8 0%,
    #1cd359 30%,
    #198fb3 70%,
    #f4752b 100%
  );
  background-size: 100% 100%;
  transform: scaleX(1);
  transform-origin: bottom left;
}

.btn-2:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 4px;
  bottom: -10px;
  left: 0px;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
  background: linear-gradient(
    to right,
    #5f14d8 0%,
    #1cd359 30%,
    #198fb3 70%,
    #f4752b 100%
  );
}
.btn-2:hover:after {
  background: linear-gradient(
    to right,
    #5f14d8 0%,
    #1cd359 30%,
    #198fb3 70%,
    #f4752b 100%
  );
  background-size: 100% 100%;
  transform: scaleX(1);
  transform-origin: bottom left;
}
.project-small-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 85vw;
}

.work-btn {
  font-family: "Roobert-Regular";
  font-size: calc(0.5vw + 1vh + 0.8vmin);
  text-decoration: none;
  color: black;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
  font-weight: 500;
}
.work-btn {
  background: linear-gradient(to right, #f4752b 0%, #9c3e07 100%);
  transition: 0.5s;
  font-family: "Roobert-Regular";
  padding: 12px 34px;
  border-radius: 50px;
  background-size: 300% 300%;
}
.work-btn:hover {
  background-position: right center;
  cursor: pointer;
}

@media screen and (max-device-width: 1480px) {
  .work-main {
    margin-left: auto;
    margin-right: auto;
  }
  .project-latest-image {
    height: auto;
  }
}

@media screen and (max-device-width: 812px) {
  .work-main {
    width: 100%;
  }
  .project-latest-image {
    width: 95vw;
    height: 35vh;
  }

  .project-description-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90vw;
    position: relative;
  }

  .case-study-btn {
    text-decoration: none;
    font-size: 22px;
    color: white;
    font-family: "Roobert-Regular";
  }
  .work-btn {
    display: none;
  }
}
@media screen and (max-device-width: 812px) {
  .project-latest-title {
    font-size: 24px;
    font-family: "HostMedium", sans-serif;
  }
  .project-latest-description {
    font-size: 20px;
    margin-block-start: 0.5em;
    font-family: "HostRegular", sans-serif;
  }
  .project-description-div p {
    margin-block-end: 0em;
    width: 95%;

    height: auto;
    margin-bottom: 0px;
    position: relative;
    left: 0px;
  }
  .project-description-div a {
    text-decoration: none;
    font-size: 20px;
    color: white;
    font-family: "HostMedium", sans-serif;
    position: relative;
    margin-top: 16px;
    bottom: 0px;
    left: 0px;
  }
  .project-latest-image {
    width: 95vw;

    height: calc(20vw + 12vh + 10vmin);
  }

  .howitworks1 {
    display: flex;
    flex-direction: column;
    grid-gap: 5em;
    gap: 5em;
  }
  .work-main h2 {
    font-family: "HostMedium", sans-serif;
    font-size: calc(2rem + 2vw);
  }

  .head-work p {
    color: #ffffff75;
    margin-block-end: 0px;
    margin-block-start: 0px;
    font-size: 16px;
    width: 100%;
  }
  .head-work img {
    display: none;
  }
  .head-work {
    display: flex;
    padding-left: 20px;

    flex-direction: column;

    padding-right: 20px;
  }
  .parent-work {
    margin-left: 20px;
    display: flex;
    flex-direction: column-reverse;
    margin-right: 20px;
    height: fit-content;

    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.152);
    margin-bottom: 40px;
  }
  .work-thumbnail {
    width: 100%;
    height: 40%;
  }
  .work-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .cs-title {
    padding: 16px 8px 12px 12px;
    margin-bottom: 20%;
  }
  .cs-title p {
    font-size: calc(1vw + 1vh + 1vmin);
  }
  .thumbnail {
   border-top-left-radius: 12px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 12px;
}.cta-cs {
  padding: 16px 12px 16px 12px;
}
.tag {
  margin-bottom: 10px;
}
.view-all {
  display: flex;
  flex-direction: column;
gap: 20px;
padding-left: 20px;
padding-right: 20px;
  align-items: flex-start;
}
.project-btn3 {
  font-family: "HostMedium", Sans-Serif;
  padding: 10px 12px !important;
  
}
}
