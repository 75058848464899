.pricing-section {
  display: flex;
  padding: 50px;
  min-height: 100vh;
  background-image: linear-gradient(0deg, #45454532 0%, #99999900 100%);
  position: relative;
  margin-bottom: calc(6vw + 6vh + 6vmin);
}

.pricing-head {
  font-family: "HostMedium", Sans-Serif;
  font-weight: 100;
  font-size: calc(1.2vw + 1.2vh + 1.2vmin);
  margin-block-start: 0.2em;
  margin-block-end: 0.6em;
}

.pricing-para {
  font-family: "HostLight", Sans-Serif;
  font-weight: 100;
  font-size: calc(0.6vw + 0.5vh + 0.5vmin);
  margin-block-start: 0.2em;
  margin-block-end: 2em;

  opacity: 0.4;
}



.left-column {
  flex: 1;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  height: fit-content;
}

.right-column {
  flex: 1.6 1;
  color: #e0e0e0;
  padding-left: 40px;
}

.right-column h2 {
  font-family: "HostLight", Sans-Serif;
  border: 1px solid rgb(251, 0, 246);
  padding: 6px 20px;
  background-color: rgba(251, 0, 246, 0.05);
  border-radius: 50px;
  color: white;
  font-size: 16px;
  width: fit-content;
  font-weight: 100;
}

.card {
  background-color: #00000051;
  padding: 24px 24px 24px 24px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border: 1px solid rgba(255, 255, 255, 0.088);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card h2,
.card h3 {
  color: #00ffff;
  font-family: "EditorialNew-Light", serif;
  font-weight: 100;
  font-size: calc(1.2vw + 1.2vh + 1.2vmin);
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
}

.card p {
  font-family: "HostLight", Sans-Serif;
  margin-block-start: 0.2em;
  margin-block-end: 0.5em;
  color: rgb(255, 255, 255);
  font-size: calc(0.5vw + 0.6vh + 0.5vmin);
  font-weight: 100;
}

.card ul {
  list-style: none;
  padding: 0;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  font-family: "HostLight", Sans-Serif;
  font-size: calc(0.5vw + 0.6vh + 0.5vmin);
  font-weight: 100;
}

.card ul li {
  margin-bottom: 10px;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  font-family: "HostLight", Sans-Serif;
  font-size: calc(0.5vw + 0.6vh + 0.5vmin);
  font-weight: 100;
}

.card ul li::before {
  color: #ff00ff;
}
.faq-chips{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 16px;
  gap: 6px;
}

button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #444;
}

.miniature-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

.about-desc span {
  font-family: "EditorialNew-Light", serif;
  color: white;
  font-size: calc(3.5vw + 3.5vh + 3.5vmin);
  line-height: calc(3.5vw + 3.5vh + 3.5vmin);
  color: #00fff7;
}
.about-desc {
  font-family: "HostLight", Sans-Serif;
  color: white;
  margin-top: calc(12vw + 12vh + 15vmin);
  padding-bottom: calc(5vw + 5vh + 5vmin);
  font-size: calc(3.5vw + 3.5vh + 3.5vmin);
  line-height: calc(3.5vw + 3.5vh + 3.5vmin);
  width: 100%;
  padding-left: calc(2vw + 2vh + 2vmin);
}

.testimonial {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-top: 20px;
  padding-right: 20px;
  height: 400px;
  width: calc(22vw + 22vh + 22vmin);
  border: 1px solid rgba(234, 233, 255, 0.099);
  background-color: #0c0b0f;
  flex-direction: column;
  border-radius: 12px;
}

.swiper {
  overflow: visible !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 70px !important;
  left: auto;
  top: -80px !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  right: 160px !important;
  left: auto !important;
  top: -80px !important;
}

.swiper-button-next {
  right: 10px;
  background-image: url("../Assets/arrow_right.svg");
  background-size: cover;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;

  padding: 12px 24px 12px 24px;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0) !important;
}

.swiper-button-prev {
  left: 10px;
  background-image: url("../Assets/arrow_left.svg");
  background-size: cover;
}

.swiper-button-next svg,
.swiper-button-prev svg {
  fill: white;
  width: 24px;
  height: 24px;
}

.author-name {
  font-family: "EditorialNew-Light", serif;
  font-size: calc(1rem + 1vw);
  color: #fb00f6;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
}

.company {
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
  font-family: "Helivetica-light", Sans-Serif;
  font-size: calc(0.5rem + 0.5vw);
  color: white;
}

.testimonial-div {
  padding-left: 80px;
}
.test-body {
  font-family: "HostLight", Sans-Serif;
  color: white;
  font-weight: 100;
  font-size: calc(1rem + 0.5vw);
}
.test-head {
  padding-left: 80px;
  font-family: "HostMedium", Sans-Serif;
  color: #00fff7;
  font-weight: 100;
  width: 40%;
  line-height: 1em;
  font-size: calc(2rem + 2vw);
  margin-top: 10vh;
}
.test-head span {
  font-family: "EditorialNew-Light", serif;
  font-weight: 100;
  color: #00fff7;
  font-size: calc(2rem + 3.5vw);
}

.head-img-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: end;
}
.getintouch {
  width: 100%;
  padding-left: 60px;
  text-align: left;
}

.faq-title {
  font-family: "HostMedium", Sans-Serif;
  font-size: calc(2rem + 2vw);
  color: #00fff7;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
  flex: 0.5 0;
}
.faq-container {
  padding-left: 70px;
  padding-right: 30px;
  margin: 0 auto;
  gap: 70px;
  display: flex;
  flex-direction: row;
}

.faq-item {
  padding-left: 30px;
  padding-right: 10px;
  border-bottom: 1px solid #ffffff21;
  border-radius: 5px;
  margin-bottom: 10px;
}

.faq-sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.faq-question {
  font-family: "HostLight", Sans-Serif;
  color: white;
  font-weight: 100;
  font-size: calc(1rem + 0.5vw);
  cursor: pointer;
  margin-block-start: 1em;
  margin-block-end: 0em;
}

.toggle-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.icon {
  transition: transform 0.3s ease;
  fill: white;
}

.rotate {
  transform: rotate(180deg);
}

.vertical {
  transition: opacity 0.3s ease;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.faq-answer {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-out, opacity 0.7s ease-out;
  font-family: "HostLight", Sans-Serif;
  color: rgba(255, 255, 255, 0.373);
  font-weight: 100;
  font-size: calc(0.8rem + 0.5vw);
  cursor: pointer;
}
.faq-ans-head {
  font-family: "HostRegular", Sans-Serif;
  color: #ec6eea;
}

.faqs {
  flex: 1 0;
}

.faq-answer.faq-open {
  max-height: 700px;
  opacity: 1;
}
.rounded-full {
  border-radius: 9999px;
}
.p-2 {
  padding: 0.5rem;
}
.fill-white {
  fill: #fff;
}
.shrink-0 {
  flex-shrink: 0;
}
.border {
  border: 1px solid rgba(255, 255, 255, 0.384);
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}
.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}
svg {
  vertical-align: middle;
}

.GIT {
  margin-top: calc(6rem + 6vw);
}
.div-reach {
  font-family: "HostRegular", sans-serif;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 90vw;
  letter-spacing: -1px;
  word-spacing: -2px;
  text-align: center;
  font-size: calc(1.5rem + 0.8vw);
  color: #011919;
  justify-content: space-between;
}

.team {
  justify-content: center;
  display: flex;
  gap: 30px;
}
.name span {
  font-family: "EditorialNew-LightItalic", serif;
}

.harshit {
  display: flex;
  width: calc(40rem + 0.8vw);
  padding: 15px;
  background-color: #171717;
  justify-content: space-around;
  gap: 27px;
}
.himanshu {
  display: flex;
  width: calc(40rem + 0.8vw);
  padding: 15px;
  background-color: #171717;
  justify-content: space-around;
  gap: 27px;
}

.name {
  font-family: "HostLight", Sans-Serif;
  color: #ff631d;
  font-size: calc(1rem + 1vw);
  margin-block-end: 0em;
  margin-block-start: 0em;
}

.exp,
.desc {
  font-family: "HostLight", Sans-Serif;
  color: white;
  font-size: calc(0.2rem + 1vw);
  margin-block-end: 0.2em;
  margin-block-start: 0.5em;
}

.links {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  font-family: "HostLight", Sans-Serif;
  color: white;
  font-size: calc(0.3rem + 1vw);
}

.links a {
  text-decoration: none;
  color: wheat;
  text-decoration: underline;
}

.team-img {
  width: calc(8rem + 8vw);
  height: auto;
}

.team-head {
  padding-left: 70px;
  font-family: "HostLight", Sans-Serif;
  font-size: calc(2rem + 2.5vw);
  line-height: 1em;
  color: #00fff7;

  margin-block-end: 0.5em;
  margin-block-start: 0.5em;
}

.strategy {
  font-family: "EditorialNew-LightItalic", serif;
  color: #fb00f6;
  font-weight: 100;
  font-size: calc(2rem + 2.5vw);
}

.approach h1 {
  color: #fb00f6;
  font-weight: 100;

  line-height: 1em;

  text-transform: lowercase;
  margin-block-end: 0em;
  margin-block-start: 0.2em;
}
.approach {
  margin-top: calc(8rem + 8vw);
  margin-bottom: calc(8rem + 8vw);
  display: flex;
  gap: 30px;
  padding-left: 70px;
  padding-right: 70px;
}

.huge-impact {
  display: block;
  display: flex;
  flex-direction: column;

  width: 200%;
}

.huge-impact span {
  font-family: "HostMedium", serif;
  font-size: calc(1.2rem + 1.2vw);
  line-height: 1em;
  color: white;
}

.approach p {
  width: 80%;
  font-family: "HostLight", Sans-Serif;
  color: #7e7e7e;
  font-size: calc(0.6rem + 0.6vw);
  margin-block-end: 02em;
  line-height: 1.5em;
  margin-block-start: 0.2em;
}
.small_changes {
  font-family: "EditorialNew-Light", serif;
  color: #fb00f6;
  font-weight: 100;
  text-transform: lowercase;
  font-size: calc(2.5rem + 2.5vw);
}

.start-day {
  margin-top: calc(8rem + 8vw);
}
.howitworks-main {
  margin: 70px;
}
.start-head {
  margin-bottom: calc(4rem + 4vw) !important;
}

.howitworks {
  display: flex;
  gap: 5em;
  padding: 50px 50px 50px 50px;
  border: solid 1px rgb(26, 26, 26);
  margin-bottom: calc(1.5rem + 1.5vw);
}

.howitworks-sub {
  border: solid 1px rgb(26, 26, 26);
  padding: 50px 50px 50px 50px;
  margin-bottom: calc(1.5rem + 1.5vw);
}
.howitworks1 {
  display: flex;
  gap: 5em;
}

.book-min {
  display: flex;
  gap: 20px;
}

.howitworks-main h2 {
  font-family: "HostMedium", serif;
  width: 60%;
  font-size: calc(1.5rem + 1.5vw);
  line-height: calc(1.5rem + 1.5vw);
  font-weight: 100;
  color: #ffffff;
  margin-block-start: 0;
}
.howitworks-main h3 {
  font-family: "EditorialNew-Light", serif;

  font-size: calc(1rem + 1vw);
  font-weight: 100;
  color: #787878;
  margin-block-start: 5px;
  margin-block-end: 0;
}

.howitworks-main p {
  font-family: "HostLight", serif;
  width: 50%;
  font-size: calc(0.5rem + 0.8vw);
  font-weight: 100;
  color: #787878;
}

.text-h {
  width: 100%;
  padding-left: 70px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.arrow {
  display: inline-block;
  margin-left: 5px;
  transform: translateX(0);
  transition: transform 0.3s ease;
}
.getintouch:hover .arrow {
  transform: translateX(15px);
}

.text-p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: "EditorialNew-Light", serif;
  color: white;
}
.text-a {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: "EditorialNew-Light", serif;
  color: white;
}
.team-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.getintouch h1 {
  font-family: "EditorialNew-LightItalic", serif;
  color: #00fff7;
  font-size: calc(7rem + 7vw);
  text-transform: lowercase;
  font-weight: 100;
}
@media screen and (max-device-width: 1200px) {
  .about {
    margin-bottom: 0px;
  }
}
@media screen and (max-device-width: 812px) {
  .miniature-img {
    width: 70%;
  }

  .about-desc {
    font-size: 20px;
    line-height: 1.4em;
    width: 80%;
  }
  .about {
    margin-bottom: 0px;
  }
}
@media screen and (max-device-width: 500px) {
  .about-desc {
    width: 90vw;
    text-align: left;
    height: 50vh;
    font-size: calc(4vw + 4vh + 4vmin);
    line-height: 6rem;
    padding-left: calc(1.5vw + 1.4vh + 1vmin);
  }

  .about-desc span {
    width: 90%;
    height: 50vh;
    font-size: calc(5vw + 5vh + 5vmin);
  }

  .howitworks-sub {
    border: solid 1px rgb(26, 26, 26);
    padding: 20px 20px 20px 20px;
    margin-bottom: calc(1.5rem + 1.5vw);
  }
  .howitworks-main h2 {
    font-family: "HostMedium", serif;
    width: 90%;
    font-size: calc(1.5rem + 1.5vw);
    line-height: calc(1.5rem + 1.5vw);
    font-weight: 100;
    color: #ffffff;
    margin-block-start: 0;
  }
  .book-min {
    display: block;
  }
  .howitworks1 {
    display: flex;
    gap: 0em;
  }

  .howitworks {
    border: solid 1px rgb(26, 26, 26);
    padding: 20px 20px 20px 20px;
    margin-bottom: calc(1.5rem + 1.5vw);
    display: flex;
    flex-direction: column;
    gap: 0em;
  }

  .howitworks1 {
    display: flex;
    gap: 0em;
  }

  .howitworks-main p {
    font-family: "HostLight", serif;
    width: 100%;
    font-size: calc(0.6rem + 0.8vw);
    font-weight: 100;
    color: #787878;
    margin-block-start: 0em;
  }
  .howitworks-main {
    width: fit-content;
  }
  .howitworks-main {
    margin: 20px;
}

.pricing-section {
  display: flex;
  flex-direction: column;

  padding: 20px;
  min-height: 100vh;
  background-image: linear-gradient(0deg, #45454532 0%, #99999900 100%);
  position: relative;
  margin-bottom: calc(6vw + 6vh + 6vmin);
}
.right-column {
 
  padding-left: 0px;
}
.right-column h2 {
 font-size: 12px;
}
.card {
  
  padding: 12px 12px 12px 12px;
  
}
  .approach {
    margin-top: calc(5rem + 5vw);
    margin-bottom: calc(8rem + 8vw);
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }

  .card h2, .card h3 {
    color: #00ffff;
    font-family: "EditorialNew-Light", serif;
    font-weight: 100;
    font-size: calc(2vw + 1.8vh + 1.2vmin);

}
.card p {

  font-size: calc(1vw + 1vh + 1vmin);

}
.card ul li {
  
  font-size: calc(1vw + 1vh + 1vmin);

}
.pricing-para {
  
  font-size: calc(1vw + 1vh + 1vmin);
  
}
.pricing-head {

  font-size: calc(2vw + 1.8vh + 1.8vmin);

}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  right: 160px !important;
  left: auto !important;
  bottom: -0px !important;
  display: none !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  display: none !important;
}
  .approach p {
    width: 100%;
    font-family: "HostLight", Sans-Serif;
    color: #7e7e7e;
    font-size: calc(0.6rem + 0.8vw);
    margin-block-end: 02em;
    line-height: 1.5em;
    margin-block-start: 0.2em;
  }
  .test-head {
    padding-left: 20px;
    margin-top: 20vh;
    color: #00fff7;
    font-weight: 100;
    width: 90%;
    line-height: 0.8em;
    font-size: calc(2rem + 3.5vw);
  }
  .testimonial-div {
    padding-left: 20px;
  }
  .testimonial {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-top: 10px;
    padding-right: 20px;
    margin-right: 30px;
    height: fit-content;
    width: 300px;
    background-color: #0b0b0b;
    flex-direction: column;
    border-radius: 5px;
  }
  .author-name {
   
    font-size: calc(1.5rem + 1.5vw);
    
}
.company {
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
  font-family: "Helivetica-light", Sans-Serif;
  font-size: calc(1rem + 1vw);
  color: white;
}

.faq-container {
  padding-left: 0px;
  padding-right: 0px;

 
}
.faq-item {
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: 1px solid #ffffff21;
  border-radius: 5px;
  margin-bottom: 10px;
}
.faq-chips{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 16px;
  gap: 6px;
}
.faq-sub {
  gap:20px
}

  .getintouch h1 {
    font-family: "EditorialNew-LightItalic", serif;
    color: #00fff7;
    font-size: calc(3rem + 3vw);
    text-transform: lowercase;
    font-weight: 100;
  }
  .text-h {
    width: 100%;
    padding-left: 20px;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }
}
