.footer {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  max-width: 100%;

}
.line-1 {
  height: 1px;
  background: rgb(255, 255, 255);
  margin-bottom: -20px;
  opacity: 0.2;
}
.line-2 {
  height: 1px;
  background: rgb(255, 255, 255);
  margin-top: -20px;
  margin-bottom: -20px;
  opacity: 0.2;
}
.social {
  display: flex;
  flex-direction: column;
}

.email-div {
  display: flex;
  flex-direction: column;
  padding-bottom: 15vh;
  width: 100%;
  padding-top: 80px;
  padding-left: 60px;
  
  background-image: url('../Assets/footerImg-filled.svg');
  background-size: cover;
  transition: background-image 0.2s ease
}

.email-div:hover{
  background-image: url('../Assets/footerImg.svg');
}




.email-div p {
  font-family: "Helivetica-light", Sans-Serif;
  font-size: calc(0.5vw + 0.5vh + 1vmin);
  color: rgba(255, 255, 255, 0.8);
 
  position: relative;
  
  margin-bottom: 20px;
  text-transform: lowercase;
}
.people-miniature {
  font-family: "HostMedium", serif;
  font-size: calc(2.5vw + 2.5vh + 2.5vmin);
  color: white;
 
  position: relative;
  text-decoration: none;
  
}

.people-miniature:hover {
  
  cursor: pointer;
}

.social p {
  font-family: "Roobert-Regular";
  font-size: calc(1.5vw + 1.2vh + 1vmin);
  width: 95%;
  
  position: relative;
  left: 60px;
  transition: ease 0.2s;
}

.social p:hover {
  transform: translateX(30px);
  cursor: pointer;
}


.linkedin,
.dribbble,
.twitter,
.insta,
.behance {
  color: rgb(223, 223, 223);
}

.linkedin:hover {
  color: rgb(31, 95, 231);

}
.dribbble:hover {
  color: rgb(253, 48, 168);
}

.insta:hover {
  color: rgb(231, 31, 114);
  background: -webkit-linear-gradient(rgb(159, 56, 255),rgb(224, 67, 255) ,rgb(255, 67, 92));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.twitter:hover {
  color: rgb(31, 161, 236);
}

.behance:hover {
  color: rgb(40, 52, 216);
}
.footer-wrapper {
  position: relative;
 
}
.miniature-footer {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  background-color: white;
  padding: 10px 5px;
  transform: rotate(-5deg);
  width: 130vw;
  position: absolute;

}
.footerImg {
height: calc(1.5vw + 1.5vh + 1.5vmin);
  width:auto;
  display: inline-block;
  
}


@media screen and (max-device-width: 1480px) {
  .social p {
    left: 60px;
  }
  
  
}

@media screen and (max-device-width: 812px) {

  .people-miniature {
    font-size: 2.4em;
    width: 65%;
    left: 30px;
    overflow-wrap: break-word;
    line-break: auto;
    
  }
  .email-div p {
    font-size: 1.2em;
    width: 80%;
    position: relative;
    left: 30px;
    margin-bottom: 10px;
  }
  .email-div a {
    font-size:2.5em;
    width: 90%;
   
  }

  .email-div {
    margin-top: 50px;
    margin-bottom: 0vh;
  }

  .footer-wrapper {
    max-width: 100%;
    position: relative;
  }

  .miniature-footer {
    overflow: hidden;
    width: 350%;
  }

  .social p {
    font-family: "Roobert-Regular";
    font-size: 2em;
    width: 60%;
    position: relative;
    left: 30px;
    transition: ease 0.2s;
  }

  .social-links {
    margin-top: 20%;
  }
  .email-div {
           display: flex;
        flex-direction: column;
        padding-bottom: 10vh;
        width: 100%;
        padding-top: 0px;
        padding-left: 00px;
    
    background-image: url('../Assets/footerImg-filled.svg');
    background-size: cover;
    transition: background-image 0.2s ease
  }
}
