.work-main-div {
  position: relative;
  top: 20vh;
  width: 90%;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20%;
}



.work {
  width: 48%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}
.work-image {
  width: 100%;
  
  height: calc(15vw + 10vh + 10vmin);

}
.work-name-btn {
  width: 100%;
  bottom: -20px;
 
  font-family: "EditorialNew-Light";
}

.work a {
  font-size: calc(0.5vw + 1vh + 0.8vmin);
  font-family: "HostRegular";
}
.work p {
  color: white;
  font-size: calc(0.5vw + 1vh + 01vmin);
  line-height: 1.3em;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  line-clamp: 8;
  -webkit-box-orient: vertical;
  width: 80%;
  margin-block-end: 0.5em;
}



@media screen and (max-device-width: 900px) {

  .work-main-div {
    width: 95%;
    margin-bottom: 30%;
  }
  .work {
    width:100%;
  }
  
}
@media screen and (max-device-width: 500px) {
  .work-main h1 {
    font-family: "EditorialNew-Light", serif;
    font-size: calc(2rem + 2vw);
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-weight: 100;
    width: auto;
    margin-left: 0px;
    overflow-x: hidden;
    white-space: nowrap;
    color: #FB00F6;
    text-align: left;
    mix-blend-mode: difference;
}
  .work a {
    font-size: 20px;
  }
  .work p {
    color: white;
    font-size: 20px;
 
    width: 95%;
  }
  .work-image {
    width: 100%;
    
    height: calc(20vw + 12vh + 10vmin);
 
  }
  .work-main-div {
  
    width: 95%;
    gap: 0px;
    margin-bottom: calc(20vw + 12vh + 10vmin);
  }
}
