@font-face {
  font-family: "Roobert-Medium";
  src: url(../Assets/Fonts/Roobert-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "Roobert-Regular";
  src: url(../Assets/Fonts/Roobert-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Roobert-Semibold";
  src: url(../Assets/Fonts/Roobert-SemiBold.woff2) format("woff2");
}

.nav-content {
  display: flex;
  gap: 40px;
  font-size: calc(0.7vw + 0.5vh + 0.8vmin);
  font-family: "HostRegular", sans-serif;
  color: rgb(255, 255, 255);
  position: absolute;
  z-index: 9999999;
  right: 60px;
  align-items: center;
  top: 30px;
}

.nav-content2 {
  display: flex;
  gap: 60px;
  font-size: calc(0.7vw + 0.5vh + 0.5vmin);
  font-family: "HostRegular", sans-serif;
  color: rgb(0, 0, 0);
  z-index: 0;
  right: 0px;
  align-items: center;
  padding-right: 60px;
}

.work-nav {
  text-decoration: none;
  font-family: "HostRegular", sans-serif;
  color: rgb(255, 255, 255);
  display: inline-block;
  position: relative;
}
.work-nav-2 {
  text-decoration: none;
  font-family: "HostRegular", sans-serif;
  color: rgb(255, 255, 255);
  display: inline-block;
  position: relative;
}

.scrolly-div {
  font-family: "Helivetica-regular", Sans-Serif;
  font-size: calc(4rem + 3vw);
  text-align: center;
  line-height: 1em;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20vh;
}
.head-img {
  height: calc(10rem + 10vw);
  width: auto;
  margin-right: calc(1rem + 1vw);
}
.scrolly-div span {
  font-family: "EditorialNew-Light", serif;
  color: #fb00f6;
}



.navigation {
  position: fixed;
  display: flex;
  background-color: #04010c2c;

}
.navigation2 {
  display: flex;
  background-color: #04010c;
  height: 70px;
  left: 0;
  padding-left: 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
}
.navigation {
  display: flex;
  background-color: #04010cc9;
  height: 70px;
  left: 0;
  padding-left: 0px;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
 
}

.project-btn a {
  color: black;
}
.project-btn2 a {
  color: black;
}

.project-btn {
  font-family: "HostMedium", Sans-Serif;
  padding: 12px 24px;
  border: none;
  border-radius: 16px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background-color: white;
  box-shadow: inset 1px 1px #cfcfcf, inset -1px -1px #ccc,
    2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}
.project-btn2-nav {
  font-family: "HostMedium", Sans-Serif;
  padding: 10px 16px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background-color: white;
  box-shadow: inset 1px 1px #cfcfcf, inset -1px -1px #ccc,
    2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: Black;
}

.project-btn2 {
  font-family: "HostMedium", Sans-Serif;
  padding: 10px 16px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background-color: white;
  box-shadow: inset 1px 1px #cfcfcf, inset -1px -1px #ccc,
    2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  color: Black;
}

.project-btn:hover {
  box-shadow: inset 2px 2px #fff, inset -2px -2px #ccc,
    4px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: box-shadow 1s ease-in;
}
.project-btn2-nav:hover {
  box-shadow: inset 2px 2px #fff, inset -2px -2px #ccc,
    4px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: box-shadow 1s ease-in;
  color: black;
}
.project-btn2:hover {
  box-shadow: inset 2px 2px #fff, inset -2px -2px #ccc,
    4px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: box-shadow 1s ease-in;
  color: black;
}

.logo {
  width: 100px;
  position: absolute;
  left: 80px;
  top: 30px;
}
.logo2 {
  width: 200px;
  padding-left: 60px;
  mix-blend-mode: difference;
}

.logo-small {
  width: 14%;
  position: absolute;
  left: 60px;
  top: 30px;
  display: none;
}
.logo-small2 {
  width: 14%;
  position: absolute;
  left: 60px;
  top: 30px;
  display: none;
}

.work-nav2 {
  text-decoration: none;
  font-family: "HostRegular", sans-serif;
  color: rgb(255, 255, 255);
  display: inline-block;
  position: relative;
}

.work-nav2-2 {
  text-decoration: none;
  font-family: "HostRegular", sans-serif;
  color: rgb(255, 255, 255);
  display: inline-block;
  position: relative;
}

.work-nav2-2::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -5px;
  left: 0;
  background: linear-gradient(to right, #f4752b 0%, #9c3e07 100%);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.work-nav2::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -5px;
  left: 0;
  background: linear-gradient(to right, #f4752b 0%, #9c3e07 100%);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.work-nav2:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.work-nav2-2:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.work-nav:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -5px;
  left: 0;
  background: linear-gradient(to right, #f4752b 0%, #9c3e07 100%);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.work-nav:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.work-nav-2:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -5px;
  left: 0;
  background: linear-gradient(to right, #f4752b 0%, #9c3e07 100%);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.work-nav-2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media screen and (max-device-width: 812px) {
  .scrolly-div {
    font-family: "Helivetica-regular", Sans-Serif;
    font-size: calc(2rem + 3vw) !important;
    text-align: center;
    line-height: 1em;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .logo2 {
    width: 50px;
    padding-left: 20px;
  }
  .react-scrollama-0 {
    margin: 0vh !important;
  }
  .nav-content2 {
    gap: 50px;
    font-size: calc(1.2vw + 1.5vh + 1.5vmin);
    font-family: "HostRegular", sans-serif;
    color: rgb(0, 0, 0);
    z-index: 9999999;

    align-items: center;
    padding-right:none;
  }
  .work-nav {
    display: none;
  }
  .logo-small {
    width: 15%;
    position: absolute;
    left: 30px;
    top: 40px;
    display: inline;
  }
  .logo {
    display: none;
  }
  .project-btn2 {
    display: none;
  }
}
