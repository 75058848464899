.reveal-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #111;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 1.5s, opacity 1s ease-out;
}

.reveal-loader.hidden {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none; /* Ensures it's non-interactive after hiding */
}

.reveal-loader.visible {
    transform: translateY(0);
    opacity: 1;
}

.loader-content {
    color: white;
    font-size: 2rem;
}