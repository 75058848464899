@font-face {
  font-family: "Host-regular";
  src: url(../Assets/Fonts/HelveticaNowDisplay-Regular.otf);
}
@font-face {
  font-family: "Helivetica-bold";
  src: url(../Assets/Fonts/HelveticaNowDisplay-Bold.woff2);
}
@font-face {
  font-family: "HostLight";
  src: url(../Assets/Fonts/HostGrotesk-Light.woff);
}
@font-face {
  font-family: "HostRegular";
  src: url(../Assets/Fonts/HostGrotesk-Regular.woff);
}

@font-face {
  font-family: "HostMedium";
  src: url(../Assets/Fonts/HostGrotesk-Medium.woff);
}
@font-face {
  font-family: "HostBold";
  src: url(../Assets/Fonts/HostGrotesk-Bold.woff);
}

@font-face {
  font-family: "Helivetica-light";
  src: url(../Assets/Fonts/HelveticaNowDisplay-Light.woff2);
}
@font-face {
  font-family: "Helivetica-medium";
  src: url(../Assets/Fonts/HelveticaNowDisplay-Medium.woff2);
}
@font-face {
  font-family: "EditorialNew-Light";
  src: url(../Assets/Fonts/PPEditorialNew-Ultralight-BF644b21500d0c0.woff2);
}
@font-face {
  font-family: "EditorialNew-LightItalic";
  src: url(../Assets/Fonts/PPEditorialNew-UltralightItalic-BF644b214ff1e9b.woff2);
}
@font-face {
  font-family: "EditorialNew-regular";
  src: url(../Assets/Fonts/PPEditorialNew-Regular-BF644b214ff145f.woff2);
}
@font-face {
  font-family: "EditorialNew-Italic";
  src: url(../Assets/Fonts/PPEditorialNew-Italic-BF644b214fb0c0a.woff2);
}

.hero h1 {
  font-family: "HostBold", Sans-Serif;
  color: white;
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
}
.spline-watermark {
  display: none !important; /* Allow clicks to pass through */
}

.bg{
 display: none;
}

.hero {
  /* background-image: url("https://ik.imagekit.io/harshitsharma/Miniature/website/bg_bSYlHde72.svg?updatedAt=1725988747698"); */
  /* background-image: url("../Assets/bg.png"); */
  background-color: #04010c;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
}

.mvp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.features {
  width: 100%;
  background-color: #1cd359;
}

.o-section.c-adv {
  font-family: "HostRegular", sans-serif;
  font-weight: 100;
  background-color: var(--1000);
  color: white;
  margin-top: -1px;
  padding-bottom: 12rem;
  padding-top: 12rem;
}

:-webkit-scrollbar {
  display: none;
}

.o-container {
  z-index: 2;
  width: calc(70.7vw + 14.5vh + 20.5vmin);
  margin-left: auto;
  margin-right: auto;
}

.o-container.c-adv__pane {
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.8rem;
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.c-v2adv__item {
  background-color: #1c1c1c31;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.162);
  flex-flow: column;
  flex: 1;
  justify-content: space-between;
  min-width: calc(10.7vw + 10.5vh + 10.5vmin);
  padding: 2.4rem 2rem 2rem;
  display: flex;
}

.c-adv__nmb {
  color: #00fff7;
  letter-spacing: 0.01em;
  margin-bottom: 7.5rem;
  line-height: 1.3;
}

.c-adv__label {
  margin-top: auto;
  margin-bottom: 1.3rem;

  font-size: 4rem;
  font-weight: 600;
  line-height: 1;
}

.o-nhg24 {
  font-size: 1.2rem;
  font-weight: 500;
}

.o-nhg24.c-adv__txt {
  font-family: "HostLight", sans-serif;
  color: rgba(255, 255, 255, 0.473);
}

.c-adv__img {
  z-index: 2;
  pointer-events: none;
  width: 30.9rem;
  position: absolute;
  inset: -9.5rem 1.8rem auto auto;
}

[aspect="309/402"] {
  aspect-ratio: 309/402;
}

.w-embed:before,
.w-embed:after {
  content: " ";
  grid-area: 1/1/2/2;
  display: table;
}

.w-embed:after {
  clear: both;
}

.c-adv__nmb.u-gutter {
  margin-bottom: 7.9rem;
}

.c-adv__label.u-gutter {
  margin-bottom: 1.6rem;
  font-size: 4rem;
}

.w-embed img:not(.u-img, .s-rich img) {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

.u-await-holder {
  display: none;
}

.u-await-holder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  padding: 0;
}

.u-await-holder.t-bg-800 {
  background: #3d3d3d;
}

.mvp p {
  color: white;
  font-family: "HostLight", sans-serif;
  margin-block-start: 0.2em;
  margin-block-end: 0.2em;
  font-size: calc(0.5rem + 0.8vw);
}

.mvp span {
  font-family: "HostRegular", sans-serif;
  display: inline-flex;
  padding: 5px var(--Border-Radius-R6, 16px);
  justify-content: center;
  align-items: center;

  font-size: calc(0.5rem + 0.8vw);
  border-radius: 70px;
  height: fit-content;
  border: 1px solid var(--Brand-secondary, #fb00f7e1);
  background-color: #fb00f730;
}

.pattern {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  opacity: 0.4;
}

.scroll-more {
  position: relative;
  display: flex;
  gap: 12px;
  align-content: center;
  padding-left: 70px;
  font-family: "Helivetica-light", Sans-Serif;
  font-size: calc(0.8vw + 0.6vh);
  color: white;
  text-transform: uppercase;
}
.head-text-div {
  position: absolute;
  padding-left: 70px;
  text-align: left;
  bottom: 10vh;
}

.pills {
  color: white;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.ai-pd {
  font-family: "HostLight", serif;
  font-size: calc(0.5rem + 0.8vw);
  color: white;
  margin-block-start: 1em;
  margin-block-end: 0.2em;
}
.head-text-div h1 {
  display: inline-block;
  line-height: 0.8em;
  letter-spacing: -1.57px;
  line-height: 85px;
  font-size: calc(2.5rem + 2.5vw);
  width: 80%;

  /* margin-top: calc(9vw + 9vh + 9vmin); */
}
.b2b {
  font-family: "HostBold", Sans-Serif;
  font-weight: 100;
  color: #00fff7;
  font-size: calc(2.8rem + 2.5vw);
}
.p1 {
  font-family: "EditorialNew-LightItalic", serif;
  font-size: calc(4rem + 4vw);
  color: #fb00f6;
  padding-right: 50px;
  margin-block-start: 0.2em;
}
.p2 {
  font-family: "HostLight", serif;
  font-size: calc(4rem + 4vw);
  color: white;
  padding-right: 50px;
  margin-block-start: 0.2em;
  margin-block-end: 1em;
}

.logo-text {
  width: 100%;
}

.work-btn-home {
  display: none;
}

@keyframes floating {
  0% {
    transform: scale(1, 1) rotate(15deg) translate(0, 0px);
  }
  50% {
    transform: scale(0.9, 0.9) rotate(20deg) translate(0, 15px);
  }
  100% {
    transform: scale(1, 1) rotate(15deg) translate(0, -0px);
  }
}

@media screen and (max-device-width: 1200px) {
  .head-text-div {
    position: relative;
    margin: auto;
    width: 80%;
    text-align: center;
  }
}

@media screen and (min-device-width: 312px) and (max-device-width: 812px) {

  .pattern {
    display: none;
  }
  .hero {
    height: 100vh;
    overflow: hidden;
  }
  .logo-text {
    width: 50%;
    padding-left: 20px;
  }
  .mvp p {
    color: white;
    font-family: "HostLight", sans-serif;
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    font-size: calc(1rem + 1vw);
}

.mvp span {
  font-family: "HostRegular", sans-serif;
  display: inline-flex;
  padding: 5px var(--Border-Radius-R6, 16px);
  justify-content: center;
  align-items: center;
  font-size: calc(1rem + 1vw);
  border-radius: 70px;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid var(--Brand-secondary, #fb00f6a3);
  background-color: #fb00f60d;
}


.c-v2adv__item {
  background-color: #1c1c1c;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.162);
  flex-flow: column;
  flex: 1 1;
  justify-content: space-between;
  min-width: fit-content;
  padding: 1.5rem 1.5rem 1.5rem;
  display: flex;
}
.o-container.c-adv__pane {
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.8rem;
  flex-flow: wrap;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.c-adv__label {
  
  font-size: 3rem;

}
.c-adv__label.u-gutter {
  margin-bottom: 1.6rem;
  font-size: 3rem;
}
.o-container {
  padding-left: 20px;
  padding-right: 20px;
  z-index: 2;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

  .head-text-div h1 {
    color: white;
    display: inline-block;
    line-height: 1em;
    
    vertical-align: none;
    align-items: flex-start;
  }
  .ai-pd {
    font-family: "HostLight", serif;
    font-size: calc(1rem + 1vw);
    color: white;
    margin-block-start: 1em;
    margin-block-end: 0.2em;
}
  .head-text-div {
    position: absolute;
    left: 0;
    width: fit-content;
    padding-left: 20px;
    text-align: left;
  }
  .text-p {
    text-align: left;
    font-size: calc(1.5rem + 0.5vw);
  }
  .text-a {
    text-align: left;
    color: white;
    font-family: "EditorialNew-Light", serif;
    font-size: calc(2rem + 2vw);
    font-weight: 100;
    margin-block-start: 0em;
    margin-block-end: 0.4em;
  }

  .text-p a {
    text-align: left;
    font-size: calc(1.5rem + 1.5vw);
    color: rgb(170, 170, 170);
  }

  .div-reach {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    letter-spacing: -1px;
    word-spacing: -2px;
    text-align: left;
    font-size: calc(1.5rem + 0.8vw);
  }

  .work-btn-home {
    font-family: "Roobert-Medium";
    font-size: 1.4rem;
    text-decoration: none;
    color: white;
    display: inline;
    position: relative;
    top: 10%;
  }
  .scroll-more p {
    font-size: calc(1vw + 1.2vh + 1vmin);
  }
  .p1 {
    font-size: calc(2rem + 2vw);
    color: #fb00f6;
  }
  .p2 {
    font-size: calc(2rem + 2vw);

    padding-right: 30px;
  }
  .bg{
display: block;
    height: 100vh;
    width: 100vw;

  }
  .bg img{
    object-fit: cover;
  }
  .animation{
    display: none;
  }
}
