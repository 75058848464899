body {
  background-color: #04010C;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 1px;
}
::-webkit-scrollbar-track {
  background: #f1f1f100;
}
::-webkit-scrollbar-thumb {
  background: rgb(26, 26, 26);
  border-radius: 20px;
}
::selection {
  color: rgb(105, 45, 247);
  background-color: rgb(41, 238, 222);
  mix-blend-mode: exclusion;
}
html, body{
  scroll-behavior: smooth;
  width: 100%;
}
.App {

  width: 100%;
}

@media screen and (max-width: 667px) {
  html,body {
      overflow-x: hidden !important;
  }
  .App {
      max-width: 100% !important;
      overflow-x: hidden !important;
  }
}